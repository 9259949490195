import React from 'react';
import cn from 'classnames';
import styles from './index.module.sass';
import { Icon } from 'components/base';

interface Props {
	className?: string;
}

export const Footer = (props: Props) => {
	const { className, ...restProps } = props;
	return (
		<footer {...restProps} className={cn(styles.container, className)}>
			<section className={styles.contacts}>
				<div className={styles.contacts__item}>
					<p className={styles.contacts__company}>Акселератор будущих компетенций</p>
					<span className={styles.contacts__info}>
						для детей от 7 до 17 лет
					</span>
				</div>
				<div className={styles.contacts__tax}>
					<span className={styles.contacts__info}>ИНН 7702464831</span>
					<span className={styles.contacts__info}>ОГРН 1197746181038</span>
				</div>
			</section>
			<section className={styles.contacts__links}>
				<a href='https://vk.cc/cgzhk2' target='_blank' rel='noopener noreferrer'>
					<Icon icon='vk' size={24} className={styles.contacts__links__icon} />
				</a>
			</section>
		</footer>
	);
};
