import React, { useMemo, useState } from 'react';
import styles from './index.module.sass';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import Modal from 'react-modal';
import { Button, Icon, Size, TextInput, Theme } from 'components/base';
import { StorageService } from 'services/storageService';
import { removeAvatar, updateAvatar, updateProfileData } from 'actions/user';
import { setCurrentUser } from 'reducers/userSlice';
import { PasswordChangeForm } from './components/PasswordChangeForm';
import 'styles/react-modal.css';

const ProfileFormSchema = Yup.object().shape({
	email: Yup.string()
		.email()
		.required('Заполните поле'),
	first_name: Yup.string()
		.required('Заполните поле'),
	last_name: Yup.string()
		.required('Заполните поле'),
	phone_number: Yup.string()
		.required('Заполните поле')
});

export const Profile = () => {
	const dispatch = useDispatch();
	const storage = StorageService.getInstance();

	Modal.setAppElement('#root');

	const user = useSelector((state: any) => state.user.data);

	const [formError, setFormError] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const notify = (success: boolean) => {
		if (success) {
			toast.success('Данные профиля изменены', {
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				theme: 'dark',
				bodyStyle: { fontFamily: 'Circe' }
			});
		} else {
			toast.error('Не удалось обновить данные профиля', {
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				theme: 'dark',
				bodyStyle: { fontFamily: 'Circe' }
			});
		}
	}

	const openImageDialog = () => {
		const input = document.createElement('input');
		input.accept = 'image/png, image/jpeg';
		input.type = 'file';
		input.onchange = e => updateAvatar((e.target as any).files[0]).then((result) => {
			const newUserData = {
				...user,
				avatar: result?.avatar
			};

			if (!!storage.localStorageUser) {
				storage.localStorageUser = newUserData;
			}

			if (!!storage.sessionStorageUser) {
				storage.sessionStorageUser = newUserData;
			}

			dispatch(setCurrentUser(newUserData));
		});

		input.click();
	}

	const removeAvatarHandle = () => {
		removeAvatar().then(() => {
			const newUserData = {
				...user,
				avatar: ''
			};
			if (!!storage.localStorageUser) {
				storage.localStorageUser = newUserData;
			}

			if (!!storage.sessionStorageUser) {
				storage.sessionStorageUser = newUserData;
			}

			dispatch(setCurrentUser(newUserData));
		});
	}

	const toggleModal = () => {
		setIsModalOpen(!isModalOpen);
	}

	const content = useMemo(() => {
		return (
			<div className={styles.container}>
				<ToastContainer />
				<h2>Профиль</h2>
				<div className={styles.content}>
					<div className={styles.avatar}>
						<h3>Фотография</h3>
						<div className={styles.avatar__content}>
							<div className={styles.photo}>
								{
									!!user?.avatar ?
										<img src={user?.avatar} alt='avatar' /> :
										<div className={styles.plug}></div>
								}
							</div>
							<div className={styles.photo__controls}>
								<Button onClick={openImageDialog} stretch>Загрузить</Button>
								{
									!!user?.avatar &&
									<Button
										theme={Theme.THIRD}
										stretch
										onClick={removeAvatarHandle}
									>
										Удалить
									</Button>
								}
							</div>
						</div>
					</div>
					<div>
						<h3>Общая информация</h3>
						<Formik
							initialValues={{
								email: user?.email,
								first_name: user?.first_name,
								last_name: user?.last_name,
								phone_number: user?.phone_number
							}}
							validationSchema={ProfileFormSchema}
							onSubmit={(
								values
							) => {
								setIsLoading(true);

								updateProfileData({
									...values,
									phone_number: values.phone_number.replace(/\s+/g, '')
								}).then((result) => {
									const newUserData = {
										...user,
										first_name: result?.first_name,
										last_name: result?.last_name,
										phone_number: result?.phone_number
									};
									if (!!storage.localStorageUser) {
										storage.localStorageUser = newUserData;
									}

									if (!!storage.sessionStorageUser) {
										storage.sessionStorageUser = newUserData;
									}

									setIsLoading(false);

									dispatch(setCurrentUser(newUserData));
									notify(true);
								}, () => {
									setFormError('Не удалось сохранить изменения');
									setIsLoading(false);
									notify(false);
								});
							}}
						>
							{({ values, setFieldValue, isValid, resetForm }) => (
								<Form className={styles.form} noValidate>
									<TextInput
										label='E-mail'
										value={values.email}
										onChange={event => setFieldValue('email', event.target.value)}
										className={styles.form__field}
										disabled
									/>
									<TextInput
										label='Имя'
										value={values.first_name}
										onChange={event => setFieldValue('first_name', event.target.value)}
										className={styles.form__field}
									/>
									<TextInput
										label='Фамилия'
										value={values.last_name}
										onChange={event => setFieldValue('last_name', event.target.value)}
										className={styles.form__field}
									/>
									<InputMask
										mask='+7 999 999 99 99'
										value={values.phone_number}
										onChange={event => setFieldValue('phone_number', event.target.value)}
									>
										{
											(inputProps: any) => (
												<TextInput
													{...inputProps}
													label='Телефон'
													className={styles.form__field}
												/>
											)
										}
									</InputMask>
									<p className={styles.form__error}>
										{formError}
									</p>
									<Button
										stretch
										disabled={!isValid}
										type='submit'
										className={styles.button}
										isLoading={isLoading}
									>
										Сохранить
									</Button>
									<Button
										stretch
										theme={Theme.THIRD}
										onClick={resetForm}
										size={Size.L}
									>
										Сбросить изменения
									</Button>
									<Button
										stretch
										theme={Theme.THIRD}
										onClick={toggleModal}
										size={Size.L}
									>
										Сменить пароль
									</Button>
								</Form>
							)}
						</Formik>
					</div>
				</div>
				<Modal
					isOpen={isModalOpen}
					onRequestClose={toggleModal}
					contentLabel='Смена пароля'
					className={styles.modal}
					overlayClassName={styles.overlay}
					closeTimeoutMS={500}
				>
					<div className={styles.modal__controls}>
						<button onClick={toggleModal}>
							<Icon icon='close' size={20} />
						</button>
					</div>
					<PasswordChangeForm onClose={toggleModal} />
				</Modal>
			</div>
		);
	}, [user, isModalOpen]);

	return <>{content}</>;
};
