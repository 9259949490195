import React, { useEffect, useRef, useState } from 'react';
import styles from './index.module.sass';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserView, MobileView } from 'react-device-detect';
import { Menu } from 'components/Header/Menu';
import { Icon } from 'components/base';
import { StorageService } from 'services/storageService';
import useOnClickOutside from 'utils/hooks/clickOutside';
import { setCurrentUser } from 'reducers/userSlice';
import { rejectAuthInterceptor } from 'services/interceptors';
import { Roles } from 'enums/roles.enum';
import { Notifications } from './Notifications';
import { useRequest } from 'utils/hooks/useRequest';
import { getNotifications, markNotificationRead } from 'actions/user';
import { Notification, NotificationsList } from 'models/notification.interface';

import logo from 'assets/images/logo.png';

interface Props {
	className?: string;
}

export const Header = (props: Props) => {
	const { className, ...restProps } = props;

	const navigate = useNavigate();
	const storage = StorageService.getInstance();
	const dispatch = useDispatch();

	const menu = useRef<HTMLDivElement>(null);

	const user = useSelector((state: any) => state.user.data);

	const [menuIsMenuOpen, setMenuOpen] = useState(false);
	const [notifications, setNotifications] = useState<Notification[]>([]);
	const [notificationsIsOpen, setNotificationsOpen] = useState(false);
	const [desktopMenuIsMenuOpen, setDesktopMenuOpen] = useState(false);

	const [getNotificationsHandle] = useRequest<NotificationsList>(
		() => getNotifications(),
		(data: NotificationsList) => {
			setNotifications(data?.results?.filter(item => !item?.was_read));
		}
	);

	useEffect(() => {
		if (!notificationsIsOpen) {
			getNotificationsHandle();
		}
	}, [notificationsIsOpen]);

	useOnClickOutside(menu, () => {
		onDesktopMenuClose();
		onNotificationsClose();
	});

	const onMenuClose = () => {
		setMenuOpen(false);
	}

	const onNotificationsClose = () => {
		setNotificationsOpen(false);
	}

	const onDesktopMenuClose = () => {
		setDesktopMenuOpen(false);
	}

	const onLogout = () => {
		dispatch(setCurrentUser({}));
		storage.logout();
		rejectAuthInterceptor();
		navigate('/auth', { replace: true });
	}

	const onNotificationRead = (id: number) => {
		markNotificationRead(id).then(() => {
			setNotifications(notifications?.filter(item => item.id !== id));
			setNotificationsOpen(false);
		});
	}

	return (
		<>
			<Menu isOpen={menuIsMenuOpen} onClose={onMenuClose} />
			<Notifications
				isOpen={notificationsIsOpen}
				onClose={onNotificationsClose}
				onNotificationRead={onNotificationRead}
				notifications={notifications}
			/>
			<header {...restProps} className={className}>
				<BrowserView className={styles.header}>
					<div className={styles.flex}>
						<NavLink
							to='/courses'
							style={{ height: '64px' }}
						>
							<img src={logo} alt='logo' className={styles.logo} />
						</NavLink>
						<nav className={styles.navigation}>
							<ul>
								<li>
									{
										user?.role === Roles.STUDENT &&
										<NavLink
											to='/courses'
											style={({ isActive }) => ({
												color: isActive ? '#0173f4' : '',
											})}
										>
											Мои курсы
										</NavLink>
									}
									{
										(user?.role === Roles.METHODIST || user?.role === Roles.CURATOR) &&
										<NavLink
											to={`/${user?.role}-courses`}
											style={({ isActive }) => ({
												color: isActive ? '#0173f4' : '',
											})}
										>
											Курсы
										</NavLink>
									}
								</li>
								{
									user?.role === Roles.CURATOR &&
									<li>
										<NavLink
											to='/tasks'
											style={({ isActive }) => ({
												color: isActive ? '#0173f4' : '',
											})}
										>
											Решения
										</NavLink>
									</li>
								}
								{
									user?.role === Roles.STUDENT &&
									<>
										<li>
											<NavLink
												to='/faq'
												style={({ isActive }) => ({
													color: isActive ? '#0173f4' : '',
												})}
											>
												Вопросы и ответы
											</NavLink>
										</li>
										<li>
											<NavLink
												to='/contacts'
												style={({ isActive }) => ({
													color: isActive ? '#0173f4' : '',
												})}
											>
												Контакты
											</NavLink>
										</li>
									</>
								}
							</ul>
						</nav>
					</div>
					<div className={styles.flex}>
						{
							user?.role !== Roles.METHODIST &&
							<button
								className={styles.notification__button}
								onClick={() => setNotificationsOpen(!notificationsIsOpen)}
							>
								<Icon icon='notification' size={24} />
								{
									notifications?.length! > 0 &&
									<div className={styles.notification__count}>{notifications?.length}</div>
								}
							</button>
						}
						<div ref={menu} className={styles.menu}>
							<button onClick={() => setDesktopMenuOpen(!desktopMenuIsMenuOpen)}>
								<div
									className={styles.menu__avatar}
								>
									{
										!!user?.avatar ?
											<img src={user?.avatar} alt='avatar' /> :
											<div className={styles.plug}></div>
									}
								</div>
								<span>{user?.first_name}</span>
								<Icon icon='gear' size={32} className={styles.menu__icon} />
							</button>
							{
								desktopMenuIsMenuOpen &&
								<div className={styles.popup}>
									<nav>
										<ul>
											<li>
												<NavLink
													to='/profile'
													onClick={onDesktopMenuClose}
												>
													Профиль
												</NavLink>
											</li>
											<li>
												<button onClick={onLogout}>Выйти</button>
											</li>
										</ul>
									</nav>
								</div>
							}
						</div>
					</div>
				</BrowserView>
				<MobileView className={styles.header}>
					<button
						onClick={() => setMenuOpen(!menuIsMenuOpen)}
						id='page-wrap'
					>
						<Icon icon='burger' size={24} rotateDegrees={desktopMenuIsMenuOpen ? 180 : 0} />
					</button>
					<img src={logo} alt='logo' className={styles.logo} />
					<div className={styles.flex}>
						{
							user?.role !== Roles.METHODIST &&
							<button
								className={styles.notification__button}
								onClick={() => setNotificationsOpen(!notificationsIsOpen)}
							>
								<Icon icon='notification' size={24} />
								{
									notifications?.length! > 0 &&
									<div className={styles.notification__count}>{notifications?.length}</div>
								}
							</button>
						}
						<img src={user?.avatar} alt='avatar' className={styles.avatar} />
					</div>
				</MobileView>
			</header>
		</>
	);
};
