import React from 'react';
import styles from './index.module.sass';

export const Contacts = () => {
	return (
		<div className={styles.container}>
			<h2>Контакты</h2>
			<section className={styles.content}>
				<div>
					<span>Вопросы по курсу</span>
					<a href = 'mailto:androminvest@gmail.com'>
						<h3>androminvest@gmail.com</h3>
					</a>
				</div>
				<div>
					<span>Контактный номер</span>
					<a href = 'tel:+79872900593'>
						<h3>+7 (987) 290-05-93</h3>
					</a>
				</div>
				<div className={styles.link}>
					<span>Социальная сеть</span>
					<a href='https://t.me/andromspace' target='_blank' rel='noopener noreferrer'>
						<h3>Телеграм</h3>
					</a>
				</div>
				<div className={styles.link}>
					<span>Социальная сеть</span>
					<a href='https://vk.cc/cgzhk2' target='_blank' rel='noopener noreferrer'>
						<h3>Вконтакте</h3>
					</a>
				</div>
			</section>
		</div>
	);
};
